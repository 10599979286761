// used to make sure all environments have same properties
export class EnvironmentBase {
  static disable_spotlights = false;
  static environment = '';
  static palfinger_api_management = '';
  static palfinger_api_management_subs_key = '';
  static palfinger_app_gateway = '';
  static bpm_base_path = '';
  static wiki_base_path = '';
  static oidc_clientid = '';
  static oidc_scope = '';
  static scc_domain = '';
  static palshow_domain = '';
  static cart_max_valid_until_days = 548;
  static geo_json_url = '';

  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
    STATUS_PALDESK_PAGE: string;
    APP_HELP_PATH: string;
    APP_NEWS_PATH: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
    STATUS_PALDESK_PAGE: '',
    APP_HELP_PATH: '',
    APP_NEWS_PATH: '',
  };

  static domainsForApiManagementSubsKey: string[];

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
  };

  static google_api_key = '';
  static google_analytics_id = '';
  static application_insights_key = '';

  static FEATUREFLAGS: any = {
    SERVICE_CONTRACTS: false,
    BULK_ORDERS: false,
    BENEFITS: false,
    VIEW_3D: false,
    EARLY_ACCESS: false,
    PPI: false,
    MCC: false,
    PALSHOW: '',
    WHOLESALE_CHECKBOX: false,
    PROJECT_SETTINGS: false,
    SOLUTIONS_INTEGRATION: false,
    REPORTING: false,
    BEST_INVEST: '',
    DIGITAL_OFFER: '',
    MODEL_COMPARISON: '',
  };

  static privileged_users = [];
}
